import { useCallback, useMemo } from "react"
import ReactServer from "react-dom/server"

import { fromUnixTime } from "date-fns"
import * as Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"

import { mAxisBottom } from "src/components/Paradise/ParadiseCharts/sharedChartConfig"
import { IDeviceMotionReadings } from "src/data/devices/types/deviceTypes"
import { colors } from "src/ui/colors"
import {
  Box,
  Content,
  Header,
  Row,
} from "src/ui/Graphs/LineGraph/DataPointTooltip"
import { formatDate } from "src/utils/l10n"

export function ParadiseBarChart({
  data,
  tooltip,
  canExport = false,
  zoom = "x",
}: {
  data: IDeviceMotionReadings["values"]
  tooltip: {
    unit: string
    formatter?: (context: { date: Date; value: number }) => React.ReactElement
  }
  canExport?: boolean
  zoom?: "x" | "xy" | "y"
}) {
  const tooltipFormatter = useCallback(
    (context: Highcharts.TooltipFormatterContextObject) => {
      if (!context.x || !context.y) return false

      if (tooltip.formatter) {
        return ReactServer.renderToString(
          tooltip.formatter({
            date: new Date(context.x),
            value: context.y,
          })
        )
      }

      return ReactServer.renderToString(
        <Box>
          <Header>
            {formatDate({
              date: new Date(context.x).toISOString(),
              timezone: "utc",
            })}
          </Header>
          <Content>
            <Row>
              Events: {context.y} {tooltip.unit}
            </Row>
          </Content>
        </Box>
      )
    },
    [tooltip]
  )

  const options = useMemo<Highcharts.Options>(
    () => ({
      chart: {
        type: "column",
        height: 500,
        zooming: {
          type: zoom,
          resetButton: {
            position: {
              // Make sure the button doesn't overlap the hamburger menu button
              x: -40,
            },
          },
        },
      },
      exporting: {
        enabled: canExport,
        csv: {},
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      xAxis: {
        type: "datetime",
        lineWidth: mAxisBottom.strokeWidth,
        lineColor: mAxisBottom.stroke,
        tickColor: mAxisBottom.tickStroke,
      },
      yAxis: {
        type: "linear",
        title: {
          text: "",
        },
        endOnTick: true,
        startOnTick: true,
        allowDecimals: false,
      },
      series: [
        {
          type: "column",
          showInLegend: false,
          animation: true,
          data: data.map((d) => [fromUnixTime(d[0]).getTime(), d[1]]),
          borderRadius: 0,
          color: colors.brandText,
        },
      ],
      tooltip: {
        useHTML: true,
        padding: 0,
        shadow: false,
        formatter() {
          return tooltipFormatter(this)
        },
      },
    }),
    [data, tooltipFormatter, canExport, zoom]
  )

  return <HighchartsReact highcharts={Highcharts} options={options} />
}
